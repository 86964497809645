$theme-blue: #0f4c7c;
$theme-red: #9c003e;
$ivory: #F7E2C5;
$parchment: #E5BD7F;
$orange: #734F2F;

@font-face {
    font-family: 'ElfaBrush';
    src: url('/assets/fonts/ElfaBrush/ElfaBrush_gdi.eot');
    src:
         url('/assets/fonts/ElfaBrush/ElfaBrush_gdi.woff') format('woff'),
         url('/assets/fonts/ElfaBrush/ElfaBrush_gdi.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

.site-nav {
  background-color: $theme-blue !important;
}

.toc.menu {
  background-color: $theme-blue !important;
}

.ui.piled.segments:before, .ui.piled.segment:before {
  background-color: $ivory;
}

.ui.piled.segments:after, .ui.piled.segment:after {
  background-color: $orange;
}

.ui.piled.segment {
  background-color: $parchment;
  margin-bottom: 50px !important;
  margin-top: 15px !important;
}

.ui.segment {
  background-color: $ivory;
}

.header.ribbon.label {
   background-color: $theme-blue !important;
   color: $ivory !important;

   .sub.header {
     color: $parchment !important;
   }
}

.header.ribbon.label:after {
  color: $theme-blue !important;
}

.view-content {
  // background-color: #9c003e;
  background-color: $theme-red;
  background-image: url('/assets/images/diagmonds.png');
}

.view-content {
  height: 100vh;
}

.hidden.menu {
  display: none;
}

.toc.menu {
  display: none;
}

@media only screen and (max-width: 700px) {
  .ui .fixed .menu {
    display: none !important;
  }

  .site-nav.menu {
    display: none;
  }

  .toc.menu {
    display: block;
  }
}

.home-image {
  position: relative;
  margin-bottom: 50px !important;
  width: 100%;

  .home-days-countdown {
    position: absolute;
    top: 40%;
    left: 10%;
    width: 40%;
    font-size: 6vmin !important;
    color: $ivory !important;
    font-family: 'ElfaBrush', sans-serif !important;
    letter-spacing: 1px;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
  }
  .home-hours-countdown {
    position: absolute;
    top: 58%;
    left: 65%;
    width: 27%;
    font-size: 4.5vmin !important;
    color: $ivory !important;
    font-family: 'ElfaBrush', sans-serif !important;
    text-align: left;
    letter-spacing: 1px;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
  }
  .home-minutes-countdown {
    position: absolute;
    top: 80%;
    left: 33.5%;
    width: 25%;
    font-size: 3.5vmin !important;
    color: $ivory !important;
    font-family: 'ElfaBrush', sans-serif !important;
    letter-spacing: 1px;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
  }
  .home-message {
    height: 100%;
    width: 100%;
    color: $ivory !important;
    font-family: 'ElfaBrush', sans-serif !important;
    letter-spacing: 1px;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
  }
}

.picture-caption {
  font-family: 'ElfaBrush', sans-serif !important;
  letter-spacing: 1px;
}

.thumb {
  height: 150px;
}

.footer {
  position: absolute !important;
  bottom: 0;
  width: 100%;
  text-align: center;

  .copyright {
    color: $ivory !important;
  }
}
